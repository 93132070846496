import './SectionProducts.scss';
import { Container, Title } from '../../components';
import { itemsProducts } from './data';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const SectionProducts = () => {
    return (
        <section id="section_products" className="section_products">
            <Container>
                <div className="title">
                    <Title title="KATANA Products" />
                </div>
                <div className="all_products">All Products</div>
                <Swiper
                    modules={[Pagination, Autoplay]}
                    spaceBetween={30}
                    slidesPerView={5}
                    pagination={{ clickable: true }}
                    autoplay={{ delay: 3000, disableOnInteraction: false }}
                    breakpoints={{
                        1200: {
                            slidesPerView: 5,
                        },
                        1024: {
                            slidesPerView: 3
                        },
                        768: {
                            slidesPerView: 2,
                        },
                        0: {
                            slidesPerView: 1,
                        },
                    }}
                >
                    {itemsProducts.map((product) => (
                        <SwiperSlide key={product.id}>
                            <div className="product">
                                <div>
                                <img src={product.images} alt={product.label} className="product__image" />
                                <div className="product__title">{product.label}</div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </Container>
        </section>
    );
};

export default SectionProducts;