import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { RiTwitterXFill } from "react-icons/ri";


const itemsFooter = [
    {
        label: 'Facebook',
        icon: <FaFacebookF />
    },
    {
        label: 'Instagram',
        icon: <FaInstagram />
    },
    {
        label: 'X (Twitter)',
        icon: <RiTwitterXFill />
    },
    {
        label: 'LinkedIn',
        icon: <FaLinkedinIn />
    },
]

export {
    itemsFooter
}