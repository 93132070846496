import './SectionFooter.scss';
import { Container, Title, Header } from '../index';
import logo from '../../images/logo.png';
import { itemsFooter } from './data';

const SectionFooter = () => {
    return (
        <section id="section_footer" className="section_footer">
            <Container>
                <Title title="Business Info" />
                <img src={logo} alt="" />
                <div className="social__area">
                    {itemsFooter.map((item) => {
                        return (
                            <div className="social__content">
                                <div className="icon">{item.icon}</div>
                                <div className="title">{item.label}</div>
                            </div>
                        )
                    })}
                </div>
                <div className="site__area">
                    <span>https://katana-oil.id</span>
                </div>
                <Header footer/>
            </Container>
        </section>
    );
}

export default SectionFooter;