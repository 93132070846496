import './SectionMain.scss';
import { Container } from '../index';
import { Row, Col } from 'antd';
import { useIsMobile } from '../../utils/useIsMobile';
import oil from '../../images/title__oil.webp';
import logo from '../../images/logo.png';

const Main = () => {
    const isTablet = useIsMobile(1200);

    return (
        <>
            <section id="section_title" className="section_title">
                <div className="title_section__area">
                    <Row>
                        <Col xs={22} lg={16} className="title_section__title" style={{ backgroundColor: '#D5A85166' }}>
                            <h1 data-aos="fade-right">The future begins now</h1>
                            <h3 data-aos="fade-right" className="description">
                                *Last generation lubricants - <br />
                                motor oils for passenger cars
                            </h3>
                        </Col>
                    </Row>
                </div>
            </section>
            <section id="section_oil" className="section_oil">
                <Container>
                    <div className="section_oil__area">
                        <Row>
                            <Col xs={24} sm={6} md={8} xl={8} data-aos="fade-up" className="title_oil__images">
                                <img src={oil} alt="" />
                            </Col>
                            <Col xs={24} sm={18} md={16} xl={8}>
                                <div data-aos="fade-up" className="title_oil__content">
                                    <div>
                                        <div className="title_oil__name">
                                            KATANA Makuri E7 10W-40
                                        </div>
                                        <div className="title_oil__description">
                                            KATANA oil is a product with enhanced properties and high efficiency, even under extreme temperature conditions.
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            {!isTablet && <Col span={8}>
                                <div data-aos="fade-left" className="title_oil__logo">
                                    <img src={logo} alt="" />
                                </div>
                            </Col>}
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    );
}

export default Main