import oil from '../../images/oil_2.png';

const itemsProducts = [
    {
        label: 'Industrial oils for production plants',
        images: oil,
    },
    {
        label: 'Greases',
        images: oil,
    },
    {
        label: 'Cutting fluids',
        images: oil,
    },
    {
        label: 'Oil for passenger cars and service stations',
        images: oil,
    },
    {
        label: 'Oil for trucks and special machinery',
        images: oil,
    },
]

export {
    itemsProducts
}