import './SectionTechnologies.scss';
import { Container, Title } from '../index';
import { Row, Col } from 'antd';
import oil from '../../images/title__oil.webp';
import { itemsTechnologies } from './data';
import { useIsMobile } from '../../utils/useIsMobile';


const SectorTechnologies = () => {
    const isTablet = useIsMobile(996);

    return (
        <section id="section_technologies" className="section_technologies">
            <Container>
                <div className="title">
                    <Title title="Meet our technologies" uppercase />
                </div>
                <div className="description">Engine oil</div>
                <Row align="middle">
                    <Col xs={24} lg={12}>
                        {itemsTechnologies.map((item) => {
                            return (
                                <div className="content">
                                    <div className="title_technologies__area">
                                        <div className="icon">{item.icon}</div>
                                        <div className="title">{item.label}</div>
                                    </div>
                                    <div className="description" data-aos="fade-right">{item.description}</div>
                                </div>
                            )
                        })}
                    </Col>
                    {isTablet ? null :
                        <Col xs={12} lg={12}>
                            <div className="oil">
                                <img src={oil} alt="" />
                            </div>
                        </Col>
                    }

                </Row>
            </Container>
        </section>
    );
}

export default SectorTechnologies;