import './TitlePage.scss';
import React, { useEffect } from 'react';
import {
    Header,
    SectionMain,
    SectionAbout,
    SectionKatana,
    SectorAdvantages,
    SectionTechnologies,
    SectionProducts,
    SectionFooter
} from '../../components/index';
import AOS from 'aos';
import 'aos/dist/aos.css';

const TitlePage = () => {

    useEffect(() => {
        AOS.init({
            duration: 800,
            easing: 'ease-in-out',
            once: false,
        });
    }, []);


    return (
        <div className='title__area'>
            <Header />
            <SectionMain />
            <SectionAbout />
            <SectionKatana />
            <SectorAdvantages />
            <SectionTechnologies />
            <SectionProducts />
            <SectionFooter />
        </div >

    );
}

export default TitlePage;